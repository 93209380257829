
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { KioskTransactionType } from "@/models/Report";
	import type { KioskTransaction } from "@/models/Report";

	export interface TransactionGroup {
		time: Date;
		type: KioskTransactionType;
		amount: number;
		open: boolean;
		sub?: TransactionGroup[];
		items?: KioskTransaction[];
	}

	const GAP = 15 * 60 * 1000;

	@Component
	export default class extends Vue {
		@Prop() public data!: KioskTransaction[];

		public groups: TransactionGroup[] = [];

		created(): void {
			let lastTime: Date = new Date(2000, 1, 1);
			for(let t of this.data) {
				let lastGroup = this.groups[this.groups.length - 1];
				let type = t.Type;
				if(type != lastGroup?.type) {
					this.groups.push({
						time: t.Time,
						type,
						open: false,
						amount: t.Amount,
					});
				} else {
					lastGroup.amount += t.Amount;
					lastGroup.items?.push(t);
				}

				lastGroup = this.groups[this.groups.length - 1];
				lastGroup.sub ??= [];
				if(lastGroup.sub.length == 0 || t.Time.getTime() - lastTime.getTime() > GAP) {
					lastGroup.sub.push({
						time: t.Time,
						amount: t.Amount,
						type,
						open: false,
						items: [t],
					});
				} else {
					let lastSub = lastGroup.sub[lastGroup.sub.length - 1];
					lastSub.amount += t.Amount;
					lastSub.items!.push(t);
				}
				lastTime = t.Time;
				Vue.set(t, "open", false);
			}
		}

		protected getText(g: TransactionGroup): string {
			if(g.type == KioskTransactionType.Coin) return "累積兌幣";
			return "累積金額";
		}

		protected trClass(g: TransactionGroup): string {
			if(g.type == KioskTransactionType.Leapa) return "text-info";
			return "";
		}

		protected typeText(t: KioskTransaction): string {
			if(t.Type == KioskTransactionType.Coin) return "兌幣";
			return "購買點數";
		}
	}
