
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Inject, Prop } from "vue-property-decorator";

	import { pad } from "@common/date";
	import { KioskTransactionType } from "@/models/Report";
	import Base from "../Base";
	import Revenue from "./components/Revenue.vue";
	import Summary from "./components/Summary.vue";
	import Transactions from "./components/Transactions.vue";

	import Statistics from "./components/Statistics.vue";
	import type { StatisticsItem } from "./components/Statistics.vue";
	import type { KioskDailyReport } from "@/models/Report";
	import type { KioskModel } from "@common/models/kiosk";

	@Component({ components: { Revenue, Summary, Statistics, Transactions } })
	export default class extends Base {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;
		@Prop(String) public id!: string;
		@Prop(String) public date!: string;

		protected data: KioskDailyReport | null = null;

		protected type: string = "現金兌幣";

		mounted(): void {
			this.load();
		}

		protected get kiosk(): KioskModel {
			return this.data!.MerchantKiosk.Kiosk;
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.MerchantKiosk.Name;
		}

		protected get statistics(): StatisticsItem[] {
			let result: StatisticsItem[] = [];
			if(!this.data) return result;
			for(let i = 0; i < 24; i += 2) {
				result.push({
					title: `${pad(i)}:00 - ${pad(i + 2)}:00`,
					coin: 0,
					leapa: 0,
				});
			}
			for(let t of this.data.Transactions) {
				let h = Math.floor(t.Time.getHours() / 2);
				if(t.Type == KioskTransactionType.Leapa) result[h].leapa += t.Amount;
				else result[h].coin += t.Amount;
			}
			return result;
		}

		private async load(): Promise<void> {
			this.data = null;
			try {
				this.data = await post<KioskDailyReport>("/api/Report/Daily", {
					Id: this.id,
					Start: this.date,
				});
				// console.log(this.data);
				this.update = new Date();
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected get transactionItems(): string[] {
			if(!this.data?.Transactions) return [];
			const set = new Set<string>();
			set.add("現金兌幣");
			this.data.Transactions.forEach(t => set.add(t.Item));
			return [...set];
		}
	}
